import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
const Footer = () => {
    const year = new Date().getFullYear();
    const { t, i18n } = useTranslation();
    return ( 
        <>
        <div className="container-fluid bg-primary mt-3">
        <div className="content-container mt-5">
            <div className="footer">
                <div className="row mt-1">
                    <div className="col-12 col-md-3 mt-5">
                        <h4 className="text-white text-center"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("site_title")}</span></h4>
                        <p className="text-white text-center">
                            <span className={i18n.language === 'en' ?  'montserrat-light': 'cairo-light'}>{t("footer_about")}</span>
                        </p>
                    </div>
                    <div className="col-12 col-md-3 mt-5">
                        <h4 className="text-white text-center"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("quick_links")}</span></h4>
                        <p className="text-center text-white"><Link to='/' className="unlink text-white"><span className={i18n.language === 'en' ?  'montserrat-light': 'cairo-light'}>{t("home")}</span></Link></p>
                        <p className="text-center text-white"><Link to='/about' className="unlink text-white"><span className={i18n.language === 'en' ?  'montserrat-light': 'cairo-light'}>{t("about")}</span></Link></p>
                        <p className="text-center text-white"><Link to='/projects' className="unlink text-white"><span className={i18n.language === 'en' ?  'montserrat-light': 'cairo-light'}>{t("projects")}</span></Link></p>
                        <p className="text-center text-white"><Link to='/services' className="unlink text-white"><span className={i18n.language === 'en' ?  'montserrat-light': 'cairo-light'}>{t("services")}</span></Link></p>
                        <p className="text-center text-white"><Link to='/contact' className="unlink text-white"><span className={i18n.language === 'en' ?  'montserrat-light': 'cairo-light'}>{t("contact")}</span></Link></p>
                    </div>
                    <div className="col-12 col-md-3 mt-5">
                        <h4 className="text-white text-center"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("contact")}</span></h4>
                        <p className="montserrat-light text-center text-white">0553355133</p>
                        <p className="montserrat-light text-center text-white">0534802656</p>
                        <p className="montserrat-light text-center text-white">admin@summitpartners-ksa.com</p>
                    </div>
                    <div className="col-12 col-md-3 mt-5">
                        <h4 className="text-white text-center"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("get_connected")}</span></h4>
                        <div className="text-center">
                            <a href="https://facebook.com/contractingsp" target="_blank" rel="noreferrer" className="unlink text-white"><i className="ri-facebook-fill px-3"></i></a>
                            <a href="https://instagram.com/contractingsp" target="_blank" rel="noreferrer" className="unlink text-white">< i className="ri-instagram-fill px-3"></i></a>
                            <a href="https://twitter.com/contractingsp" target="_blank" rel="noreferrer" className="unlink text-white">< i className="ri-twitter-fill px-3"></i></a>
                        </div>
                    </div>
                </div>
                <div className="text-white text-center mt-3">
                    <span className="text-montserrat-regular">Copyright © <span className="montserrat-bold">Summit Partners Contracting</span> {year}</span>
                </div>
            </div>
        </div>
        </div>
      </>
     );
}
 
export default Footer;