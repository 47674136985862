import {Projects} from "../../Projects";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
const HProject = () => {
    const { t, i18n  } = useTranslation();
    return ( 
        <>
        {Projects.slice(0, 3).map(data => {
            return (
            <div className="col-12 col-md-4 py-3" key={data.id}>
                <Link className="unlink" to={'/projects/' +data.id}><div className="card" >
                    <img className="card-img-top" src={data.mainImage} alt="Summit Partners Contracting Projects - مشاريع شركاء القمة للمقاولات" />
                    <div className="card-body">
                        <h4 className="card-title text-center"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t(data.title)}</span></h4>
                    </div>
                </div></Link>
            </div>)}
        )}
        </>
     );
}
 
export default HProject;