import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const HAbout = () => {
    const { t, i18n  } = useTranslation();
    return ( 
        <>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-12 col-md-6 text-center mb-3">
                        <h2 className="text-unwrap text-info"><span className={i18n.language === 'en' ?  'montserrat-black': 'cairo-black'}>{t("we_are")}</span>
                            <div className={i18n.language === 'en' ?  'sliding-words-wrapper ms-2 px-2 border border-2 border-primary': 'sliding-words-wrapper me-2 px-2 border border-2 border-primary'}>
                                {/* <span className="animating-word montserrat fw-bold text-primary">Innovative</span> */}
                                <div className="sliding-words">
                                    <span className="text-white word-border"><span className={i18n.language === 'en' ?  'montserrat-bold ps-2': 'cairo-bold pe-2'}>{t("innovative")}</span></span>
                                    <span className="text-white word-border"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("creative")}</span></span>
                                </div>
                            </div>
                        </h2>
                    </div>
                    <div className="col-12 col-md-6 text-center">
                    <h1 className="text-info"><span className={i18n.language === 'en' ?  'montserrat-black': 'cairo-black'}>{t("about_us")}</span></h1>
                    <p className="text-info mt-2"><span className={i18n.language === 'en' ?  'montserrat-light': 'cairo-light'}>{t("about_us_desc")}</span></p>
                    <Link to="/about"><button className="btn btn-secondary mt-2 px-5 habout-btn"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("learn_more_us")}</span></button></Link>
                    </div>
                </div>
            </div>
        </>
     );
}
 
export default HAbout;