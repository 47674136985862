import { useTranslation } from "react-i18next";
const About = () => {
    const { t, i18n  } = useTranslation();
    return ( 
        <>
        <div className="container mt-3">
            <h1 className="text-info mt-5 text-center"><span className={i18n.language === 'en' ?  'montserrat-black': 'cairo-black'}>{t("about_us")}</span></h1>
            <p className="text-info text-center">
                <span className={i18n.language === 'en' ?  'montserrat-light': 'cairo-light'}>{t("full_about")}</span>
            </p>
            <div className="row mt-5">
                <div className="col-12 col-md-12 col-lg-6 mt-5">
                    <h1 className="text-info text-center"><span className={i18n.language === 'en' ?  'montserrat-black': 'cairo-black'}>{t("our_approach")}</span></h1>
                    <p className="text-muted text-center"><span className={i18n.language === 'en' ?  'montserrat-regular': 'cairo-regular'}>{t("our_approach_desc")}</span></p>
                </div>
                <div className="col-12 col-md-12 col-lg-6 bg-primary">
                    <div className="row mt-3 px-3">
                        <h4 className="text-white"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("quality")}</span></h4>
                        <p className="text-white "><span className={i18n.language === 'en' ?  'montserrat-thin': 'cairo-thin'}>{t("quality_desc")}</span></p>
                    </div>
                    <div className="row mt-3 px-3">
                        <h4 className="text-white"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("speed")}</span></h4>
                        <p className="text-white "><span className={i18n.language === 'en' ?  'montserrat-thin': 'cairo-thin'}>{t("speed_desc")}</span></p>
                    </div>
                    <div className="row mt-3 px-3">
                        <h4 className="text-white"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("follow_up")}</span></h4>
                        <p className="text-white "><span className={i18n.language === 'en' ?  'montserrat-thin': 'cairo-thin'}>{t("followup_desc")}</span></p>
                    </div>
                    <div className="row mt-3 px-3">
                        <h4 className="text-white"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("precision")}</span></h4>
                        <p className="text-white "><span className={i18n.language === 'en' ?  'montserrat-thin': 'cairo-thin'}>{t("precision_desc")}</span></p>
                    </div>
                    <div className="row mt-3 px-3">
                        <h4 className="text-white"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("guarantee")}</span></h4>
                        <p className="text-white "><span className={i18n.language === 'en' ?  'montserrat-thin': 'cairo-thin'}>{t("guarantee_desc")}</span></p>
                    </div>
                </div>
            </div>
        </div>
        </>
     );
}
 
export default About;