import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Services = () => {
    const { t, i18n  } = useTranslation();
    return ( 
        <>
        <div className="container mt-3">
            <h1 className="text-info mt-5 text-center"><span className={i18n.language === 'en' ?  'montserrat-black': 'cairo-black'}>{t("our_services")}</span></h1>
            <p className="text-info text-center"><span className={i18n.language === 'en' ?  'montserrat-light': 'cairo-light'}>{t("our_services_desc")}</span></p>
            <center><ul className="hservices-list list-inline">
                <li className="list-inline-item"><i className="ri-checkbox-circle-fill text-secondary"></i><span className={i18n.language === 'en' ?  'montserrat-regular me-2': 'cairo-regular ms-2'}>{t("quality")}</span></li>
                <li className="list-inline-item"><i className="ri-checkbox-circle-fill text-secondary"></i><span className={i18n.language === 'en' ?  'montserrat-regular me-2': 'cairo-regular ms-2'}>{t("speed")}</span></li>
                <li className="list-inline-item"><i className="ri-checkbox-circle-fill text-secondary"></i><span className={i18n.language === 'en' ?  'montserrat-regular me-2': 'cairo-regular ms-2'}>{t("follow_up")}</span></li>
                <li className="list-inline-item"><i className="ri-checkbox-circle-fill text-secondary"></i><span className={i18n.language === 'en' ?  'montserrat-regular me-2': 'cairo-regular ms-2'}>{t("precision")}</span></li>
                <li className="list-inline-item"><i className="ri-checkbox-circle-fill text-secondary"></i><span className={i18n.language === 'en' ?  'montserrat-regular me-2': 'cairo-regular ms-2'}>{t("guarantee")}</span></li>
            </ul></center>
            <center><Link to="/contact"><button className="btn btn-secondary mt-2 px-5 hservice-btn"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("free_quote")}<i className={i18n.language === 'en' ?  'ri-survey-fill text-white ms-2': 'ri-survey-fill text-white me-2'}></i></span></button></Link></center>
            <div className="container-fluid border border-secondary rounded-start rounded-end mt-5">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <h3 className="text-info text-center"><span className={i18n.language === 'en' ?  'montserrat-regular': 'cairo-regular'}>{t("architecture")}</span></h3>
                        <p className="montserrat-light text-center">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</p>
                    </div>
                    <div className="col-12 col-md-4">
                        <h3 className="text-info text-center"><span className={i18n.language === 'en' ?  'montserrat-regular': 'cairo-regular'}>{t("steel_structure")}</span></h3>
                        <p className="montserrat-light text-center">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</p>
                    </div>
                    <div className="col-12 col-md-4">
                        <h3 className="text-info text-center"><span className={i18n.language === 'en' ?  'montserrat-regular': 'cairo-regular'}>{t("decor")}</span></h3>
                        <p className="montserrat-light text-center">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</p>
                    </div>
                </div>
            </div>
        </div>
        </>
     );
}
 
export default Services;