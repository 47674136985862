import Carousel1 from '../../images/carousel1.png';
import Carousel2 from '../../images/carousel2.png';
import {Link} from 'react-router-dom'
import { useTranslation } from "react-i18next";
const Carousel = () => {
    const { t, i18n  } = useTranslation();
    return ( 
        <div id="home-carousel" className="carousel slide" data-bs-ride="carousel">
            {/* Slideshow */}
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src={Carousel1} alt="Summit Partners Contracting - Construction Site | شركاء القمة للمقاولات - موقع بناء" className="d-block w-100" />
                    <div className="carousel-caption d-md-none d-lg-none">
                        <h4><span className={i18n.language === 'en' ?  'montserrat-semibold': 'cairo-semibold'}>{t("carousel1_header")}</span></h4>
                        <p className="text-break"><span className={i18n.language === 'en' ?  'montserrat-thin': 'cairo-thin'}>{t("carousel1_p")}</span></p>
                        <Link to="/projects"><button className='btn caption-btn'><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("view_projects")}</span></button></Link>
                    </div>
                    <div className="carousel-caption-md d-none d-md-block d-lg-none">
                        <h3><span className={i18n.language === 'en' ?  'montserrat-black': 'cairo-black'}>{t("carousel1_header")}</span></h3>
                        <p className="text-break"><span className={i18n.language === 'en' ?  'montserrat-extralight': 'cairo-light'}>{t("carousel1_p")}</span></p>
                        <Link to="/projects"><button className='btn caption-btn'><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("view_projects")}</span></button></Link>
                    </div>
                    <div className="carousel-caption-lg d-none d-md-none d-lg-block">
                        <h1 className="mb-4"><span className={i18n.language === 'en' ?  'montserrat-black': 'cairo-black '}>{t("carousel1_header")}</span></h1>
                        <p className="text-break"><span className={i18n.language === 'en' ?  'montserrat-extralight': 'cairo-light'}>{t("carousel1_p")}</span></p>
                        <Link to="/projects"><button className='btn caption-btn'><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("view_projects")}</span></button></Link>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src={Carousel2} alt="Summit Partners Contracting - Construction Site | شركاء القمة للمقاولات - موقع بناء" className="d-block w-100" />
                    <div className="carousel-caption d-md-none d-lg-none">
                        <h4 className="header-two"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("carousel2_header")}</span></h4>
                        <p className="text-break"><span className={i18n.language === 'en' ?  'montserrat-extralight': 'cairo-light'}>{t("carousel2_p")}</span></p>
                        {/* <Link to="/projects"><button className='btn caption-btn'>Services</button></Link> */}
                        <div className="btn-group btn-group">
                            <Link to='/services'><button type="button" className="btn caption-btn mx-2"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("services")}</span></button></Link>
                            <Link to='/contact'><button type="button" className="btn caption-btn-two text-nowrap"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("free_quote")}</span></button></Link>
                        </div>
                    </div>
                    <div className="carousel-caption-md caption-two-md d-none d-md-block d-lg-none">
                        <h3 className="header-two-md"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("carousel2_header")}</span></h3>
                        <p className="text-break"><span className={i18n.language === 'en' ?  'montserrat-extralight': 'cairo-light'}>{t("carousel2_p")}</span></p>
                        {/* <Link to="/projects"><button className='btn caption-btn'>Services</button></Link> */}
                        <div>
                            <Link to='/services'><button type="button" className="btn caption-btn mx-2"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("services")}</span></button></Link>
                            <Link to='/contact'><button type="button" className="btn caption-btn-two text-nowrap"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("free_quote")}</span></button></Link>
                        </div>
                    </div>
                    <div className="carousel-caption-lg d-none d-md-none d-lg-block">
                        <h1 className="header-two-md"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("carousel2_header")}</span></h1>
                        <p className="text-break"><span className={i18n.language === 'en' ?  'montserrat-extralight': 'cairo-light'}>{t("carousel2_p")}</span></p>
                        {/* <Link to="/projects"><button className='btn caption-btn'>Services</button></Link> */}
                        <div className="btn-group btn-group">
                            <Link to='/services'><button type="button" className="btn caption-btn mx-2"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("services")}</span></button></Link>
                            <Link to='/contact'><button type="button" className="btn caption-btn-two text-nowrap"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("free_quote")}</span></button></Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* Controllers */}
            <button className="carousel-control-prev" type="button" data-bs-target="#home-carousel" data-bs-slide="prev">
                <span className="carousel-control-prev-icon"></span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#home-carousel" data-bs-slide="next">
                <span className="carousel-control-next-icon"></span>
            </button>
        </div>
     );
}
 
export default Carousel;