// Res Images
import Res1 from './images/Res/res1.jpg'
import Res2 from './images/Res/res2.jpg'
import Res3 from './images/Res/res3.jpg'
import Res4 from './images/Res/res4.jpg'
import Res5 from './images/Res/res5.jpg'
import Res6 from './images/Res/res6.jpg'
import ResMain from './images/Res/ResMain.jpeg'
// Elec Images
import ElecMain from './images/Elec/ElecMain.jpg'
import Elec1 from './images/Elec/Elec1.jpeg'
import Elec2 from './images/Elec/Elec2.jpeg'
// Elec Images
import MechMain from './images/Mech/MainMech.jpg'
import Mech1 from './images/Mech/Mech1.jpeg'
import Mech2 from './images/Mech/Mech2.jpeg'
import Mech3 from './images/Mech/Mech3.jpeg'
import Mech4 from './images/Mech/Mech4.jpeg'
// Pharm Images
import PharmMain from './images/Pharm/PharmMain.jpeg'
import Pharm1 from './images/Pharm/Pharm1.jpeg'
import Pharm2 from './images/Pharm/Pharm2.jpeg'
import Pharm3 from './images/Pharm/Pharm3.jpeg'
import Pharm4 from './images/Pharm/Pharm4.jpeg'
import Pharm5 from './images/Pharm/Pharm5.jpeg'
export const Projects = [
    {
      title: 'Res',
      mainImage: ResMain,
      images: [Res1, Res2, Res3, Res4, Res5, Res6],
      id: 1,
      url: '1'
    },
    {
      title: 'Electric',
      mainImage: ElecMain,
      images: [Elec1, Elec2],
      id: 2,
      url: '2'
    },
    {
      title: 'Mechanic',
      mainImage: MechMain,
      images: [Mech1, Mech2, Mech3, Mech4],
      id: 3,
      url: '3'
    },
    {
      title: 'Pharma',
      mainImage: PharmMain,
      images: [Pharm1, Pharm2, Pharm3, Pharm4, Pharm5],
      id: 4,
      url: '4'
    },
  ];