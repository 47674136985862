import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import HProject from "./HProject";
const HProjects = () => {
    const { t, i18n  } = useTranslation();
    return ( 
        <>
        <div className="container mt-5">
            <h1 className="text-info text-center"><span className={i18n.language === 'en' ?  'montserrat-black': 'cairo-black'}>{t("projects")}</span></h1>
        </div>
        <div className="container">
            <div className="row">
                <HProject />
            </div>
            <center><Link to="/projects"><button className="btn btn-secondary mt-2 px-5 habout-btn"><span className={i18n.language === 'en' ?  'montserrat-bold': 'cairo-bold'}>{t("view_projects")}</span></button></Link></center>
        </div>
        </>
     );
}
export default HProjects;